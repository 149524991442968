import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Button, NamedLink } from "../../../components";
import css from './SectionSell.module.css';

const SectionSell = ({ className }) => {
    return (
        <div className={classNames(css.root, className)}>
            <span className={css.titleBold}>
                <span className={css.title}>
                    <FormattedMessage id="SectionSell.title" />
                </span>
                <FormattedMessage id="SectionSell.ownly" />
            </span>

            <p className={css.text}>
                <FormattedMessage id="SectionSell.info" values={{ lineBreak: <br /> }} />
            </p>
            <NamedLink className={css.linkContainer} name="NewListingPage">
                <Button className={css.button}>
                    <FormattedMessage id="SectionSell.sellNow" />
                </Button>
            </NamedLink>
        </div>
    );
};

export default SectionSell;